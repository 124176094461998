import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store/store';
import Wrapper from '../../../components/layout/Wrapper/Wrapper';
import GoBack from '../../../components/common/GoBack';
import { menu } from '../../../routes/menu';
import VideoPlayer from './VideoPlayer';
import Localisation from './Localisation';
import { downloadCSVFile, extractCoordinates } from '../../../utils/helpers';
import DataTable from '../../../components/datatable/Datatable';
import { CrudOptions } from '../../../types/datatable-types';
import DeleteDrawer from '../../../components/common/DeleteDrawer';
import { GetApp, Refresh } from '@mui/icons-material';
import { IHistory } from '../../../types/organisations-types';
import { headCells } from '../../history/constants/columns';
import theme from '../../../styles/theme';
import FluxSelect from '../../../components/common/FluxSelect';
import { IOneVideo } from '../../../types/flux-types';

const FluxDetails = () => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const { video, flux } = useSelector((state: RootState) => state.flux);

	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [selectedHistory, setSelectedHistory] = React.useState<IHistory>();
	const [selectedFilterFlux, setSelectedFilterFlux] = React.useState<string>('');

	const [page, setPage] = React.useState(0);
	const [rowPerPage, setRowPerPage] = React.useState(5);
	const [totalItemsCount, setTotalItemsCount] = React.useState(0);

	const handleChangeFilterFlux = (name: string) => {
		setSelectedFilterFlux(name);
		const fluxId = flux.find((video) => video.name === name)?.id;
		navigate(`../${menu.flux.path}/${fluxId}`);
	};

	React.useEffect(() => {
		const payload: { page: number; pageSize: number; id: string | undefined } = {
			page: page + 1,
			pageSize: rowPerPage,
			id: params.id,
		};
		dispatch.flux.getOne(payload).then((res: IOneVideo) => {
			setSelectedFilterFlux(res?.name);
			setTotalItemsCount(res?.totalItemsCount);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowPerPage, page, params.id]);

	const crudHandlers: CrudOptions = {
		handleArchive: (id: number) => {
			setOpenDeleteDrawer(!openDeleteDrawer);
			const history = video.history.find((hist) => hist.id === id);
			setSelectedHistory(history);
		},
	};

	const handleDelete = () => {
		dispatch.flux.deleteHistory({ id: selectedHistory?.id, videoId: params.id }).then(() => {
			setOpenDeleteDrawer(false);
		});
	};

	return (
		<Wrapper>
			<Stack
				direction={{ sm: 'column', md: 'row' }}
				alignItems='center'
				gap={2}
				justifyContent={{ sm: 'center', md: 'space-between' }}
				style={{ position: 'relative', marginBottom: '35px' }}>
				{isMobile ? (
					<>
						<Stack sx={{ width: { xs: '100%' } }} alignItems='flex-start'>
							<GoBack
								title={t('flux.Retour liste des flux')}
								path={() => navigate('/gestion-flux')}
							/>
						</Stack>
						<Typography variant='h1' textAlign='center'>
							{t('flux.Flux')} {video?.name}
						</Typography>

						<FluxSelect
							displayAll={false}
							selectedFlux={selectedFilterFlux}
							setSelectedFlux={handleChangeFilterFlux}
						/>
					</>
				) : (
					<Stack
						direction='row'
						justifyContent='space-between'
						sx={{ width: '100%' }}
						gap={2}>
						<GoBack
							title={t('flux.Retour liste des flux')}
							style={{}}
							path={() => navigate('/gestion-flux')}
						/>
						<Typography variant='h1' textAlign='center'>
							{t('flux.Flux')} {video?.name}
						</Typography>

						<FluxSelect
							displayAll={false}
							selectedFlux={selectedFilterFlux}
							setSelectedFlux={handleChangeFilterFlux}
						/>
					</Stack>
				)}
			</Stack>
			{/* <Stack
				direction={{ sm: 'column', md: 'row' }}
				justifyContent='space-between'
				gap={2}
				style={{
					marginBottom: 40,
				}}>
				<VideoPlayer videoId={video?.id} video={video} />

				{video.localisation && (
					<Localisation coordinates={extractCoordinates(video.localisation)} />
				)}
			</Stack> */}

			<Stack
				direction={{ sm: 'column', md: 'row' }}
				justifyContent='space-between'
				gap={2}
				style={{
					marginBottom: 40,
				}}>
				{/* VideoPlayer takes 2/3 of the available width */}

				<VideoPlayer videoId={video?.id} video={video} />

				{/* Localisation takes 1/3 of the available width */}
				{video?.localisation && (
					<div style={{ flex: 1 }}>
						<Localisation coordinates={extractCoordinates(video.localisation)} />
					</div>
				)}
			</Stack>
			<Box>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignContent='center'
					sx={{ marginBottom: '30px' }}>
					<Typography variant='h2'>{t('flux.Historique')}</Typography>

					<Stack direction='row' gap={2}>
						<Button
							variant='outlined'
							startIcon={<Refresh />}
							onClick={() =>
								dispatch.flux.getOne({
									id: params.id,
									page: page + 1,
									pageSize: rowPerPage,
								})
							}>
							{t('common.Refresh')}
						</Button>
						<Button
							variant='outlined'
							startIcon={<GetApp />}
							onClick={() =>
								dispatch.flux.exportCSV(video?.id).then((res: string) => {
									downloadCSVFile(res, 'moncsv.csv');
								})
							}>
							{t('common.Exporter csv.')}
						</Button>
					</Stack>
				</Stack>
				<DataTable
					columns={headCells}
					data={video.history}
					defaultSortId=''
					crudOptions={crudHandlers}
					translation='flux'
					currentPage={page}
					setCurrentPage={setPage}
					totalPageCount={totalItemsCount}
					rowPerPage={rowPerPage}
					setRowPerPage={setRowPerPage}
				/>
			</Box>

			{openDeleteDrawer && selectedHistory && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedHistory(undefined);
						setOpenDeleteDrawer(false);
					}}
					type=''
					handleDelete={handleDelete}
					data={{
						name: selectedHistory.name,
						id: selectedHistory.id,
					}}
				/>
			)}
		</Wrapper>
	);
};

export default FluxDetails;
