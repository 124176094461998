import {
	Badge,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import { IDetectionType } from '../../../types/flux-types';
import { Fullscreen, WifiOff } from '@mui/icons-material';
import useWebSocket from '../../../hooks/useWebSocket';
import useGenerateField from '../../../hooks/useGenerateField';
import { useSelectField } from '../../../hooks/useSelectField';
import { Videocam } from '@mui/icons-material';
import useFields from '../constants/fields';
import { useNavigate } from 'react-router-dom';
import { menu } from '../../../routes/menu';

interface FrameData {
	video_data: string;
	thermal?: string;
	frame: string;
	url: string;
	prediction: string;
}

interface IVideoPlayer {
	videoId: number;
	inLive?: boolean;
	data?: { image: string; date: Date; type: IDetectionType };
	video?: any;
}

const VideoPlayer: React.FC<IVideoPlayer> = ({
	videoId,
	inLive = true,
	data,
	video,
}): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { typeCamSelect } = useFields();
	const generateFields = useGenerateField();
	const { selectValues, handleSelectChange, setSelectValues } = useSelectField();

	const [error] = React.useState(false);

	const videoFrameRef = React.useRef<HTMLImageElement>(null);

	const videoFrameRefThermal = React.useRef<HTMLImageElement>(null);
	// const supervisorFlux = React.useRef<any>(null);
	// const { rgbSupervisorIsActive, thermalSupervisorIsActive } = useSelector((state: RootState) => state.flux.supervisorFlux);
	const [imageSource, setImageSource] = React.useState<string>();
	const [imageSourceThermal, setImageSourceThermal] = React.useState<string>();

	const frameCache: [string][] = React.useMemo(() => [], []);
	const frameDisplayInterval = 100;

	const frameCacheThermal: [string][] = React.useMemo(() => [], []);
	const frameDisplayIntervalThermal = 100;

	// Fonction de gestion de l'ouverture de la connexion WebSocket
	const handleOpen = () => {
		if (videoId) {
			console.info('WebSocket connection established.');
			dispatch.flux.play(videoId);
			console.info('PLAY');
		}
	};

	// Fonction de gestion de la réception des messages WebSocket
	const handleMessage = (event: MessageEvent) => {
		const data: FrameData = JSON.parse(event.data);
		const { video_data, thermal } = data;
		const newImageSource = `data:image/jpeg;base64,${video_data}`;
		const newImageSourceThermal = `data:image/jpeg;base64,${thermal}`;

		setImageSource(newImageSource);
		frameCache.push([newImageSource]);

		setImageSourceThermal(newImageSourceThermal);
		frameCacheThermal.push([newImageSourceThermal]);
	};

	/* 	// DESACTIVATED => Fonction de gestion de la fermeture de la connexion WebSocket
	const handleClose = () => {
		if (videoId) {
			dispatch.flux.status(videoId).then((res: string) => {
				if (res === 'PLAYING') {
					dispatch.flux.stop(videoId);
					setImageSource('');
					console.info('STOP');
					console.info('WebSocket connection closed.');
				}
			});
		}
	}; */

	const { isConnected: isWebSocketConnected } = useWebSocket({
		url: `video/${videoId}`,
		onMessage: handleMessage,
		// onClose: handleClose,
		onOpen: handleOpen,
	});
	console.log('is Flux Connected ?', isWebSocketConnected, 'for videoId:', videoId);

	React.useEffect(() => {
		if (data) {
			setImageSource(`data:image/jpeg;base64,${data.image}`);
			setImageSourceThermal(`data:image/jpeg;base64,${data.image}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	React.useEffect(() => {
		if (video?.camera_type != null) {
			setSelectValues({ ...selectValues, camera_type: [video?.camera_type] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [video]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (frameCache.length > 0) {
				const frameCacheItem = frameCache.shift();
				if (frameCacheItem) {
					const [imageSource] = frameCacheItem;
					setImageSource(imageSource);
				}
				setImageSource(imageSource);
			}
		}, frameDisplayInterval);

		return () => clearInterval(interval);
	}, [frameCache, imageSource]);

	React.useEffect(() => {
		const intervalThermal = setInterval(() => {
			if (frameCacheThermal.length > 0) {
				const frameCacheThermalItem = frameCacheThermal.shift();
				if (frameCacheThermalItem) {
					const [imageSourceThermal] = frameCacheThermalItem;
					setImageSourceThermal(imageSourceThermal);
				}
				setImageSourceThermal(imageSourceThermal);
			}
		}, frameDisplayIntervalThermal);

		return () => clearInterval(intervalThermal);
	}, [frameCacheThermal, imageSourceThermal]);

	const toggleFullScreenTherma = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		const videoFrameThermal = videoFrameRefThermal.current;
		if (videoFrameThermal) {
			if (videoFrameThermal.requestFullscreen) {
				videoFrameThermal.requestFullscreen();
			}
		}
	};

	const toggleFullScreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		const videoFrame = videoFrameRef.current;
		if (videoFrame) {
			if (videoFrame.requestFullscreen) {
				videoFrame.requestFullscreen();
			}
		}
	};
	// start stop Supervisor

	// React.useEffect(() => {
	// 	dispatch.flux.getSupervisorStatus();
	// }, [rgbSupervisorIsActive, thermalSupervisorIsActive]);

	// const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
	// 	event.preventDefault();

	// 	if (etatsupervisor) {
	// 		setEtatsupervisor(false);
	// 		dispatch.flux.startSupervisor();

	// 	} else {
	// 		setEtatsupervisor(true);
	// 		dispatch.flux.stopSupervisor();

	// 	}
	// 	dispatch.flux.getSupervisorStatus();
	// };

	const isScreenSplitted = (selectValues?.camera_type as string[])?.[0] === 'Splitted';
	const isVideoPlaying = video?.video_status === 'PLAYING';

	return (
		<div style={isScreenSplitted && isVideoPlaying ? { flex: 2 } : { flex: 1 }}>
			<Card sx={{ width: '100%', height: '370px' }}>
				<CardHeader>
					{inLive ? (
						isWebSocketConnected && (imageSource || isVideoPlaying) ? (
							<Stack
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								sx={{ marginBottom: '10px' }}>
								<Stack direction='row'>
									<Typography
										variant='h6'
										sx={{
											fontSize: 15,
											display: 'flex',
											alignItems: 'center',
											margin: '0 10px 0 0',
										}}
										gutterBottom>
										<Badge
											color='error'
											variant='dot'
											style={{ margin: '0 10px' }}
										/>
										{t('common.En direct')}
									</Typography>
									<Typography
										sx={{ fontSize: 15, fontWeight: 400, margin: ' 10px' }}>
										{moment().format('DD-MM-YYYY HH:mm:ss')}
									</Typography>
								</Stack>

								<Stack direction='row' alignItems='center'>
									<Videocam
										htmlColor='#9E9E9E'
										sx={{ width: '20px', height: '20px', marginRight: 1 }}
									/>
									{video?.camera_type === 'Splitted' ? (
										generateFields(
											typeCamSelect as any,
											video?.camera_type,
											error,
											selectValues,
											handleSelectChange,
										)
									) : (
										<div>{t(`flux.${video?.camera_type}`)}</div>
									)}
								</Stack>
							</Stack>
						) : null
					) : (
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							sx={{ marginBottom: '7px' }}>
							<Stack
								direction='row'
								justifyContent='space-between'
								sx={{ width: '100%' }}>
								<Box>
									<Typography
										variant='h6'
										sx={{
											fontSize: 15,
											display: 'flex',
											alignItems: 'center',
											margin: '0 10px 0 10px',
										}}
										gutterBottom>
										{capitalizeFirstLetter(data?.type ?? t('common.En panne'))}
									</Typography>
									<Typography
										sx={{ fontSize: 15, fontWeight: 400, margin: '10px' }}>
										{moment(data?.date).format('DD-MM-YYYY HH:mm:ss')}
									</Typography>
								</Box>
								<Button onClick={() => navigate(`../${menu.flux.path}/${videoId}`)}>
									{t('notifications.Voir le flux en direct')}
								</Button>
							</Stack>

							{/* <Stack>
								 <form onSubmit={handleSubmit}>
									<Button
										type='submit'
										variant='outlined'
										style={{ justifySelf: "end", alignSelf: "end", marginLeft: 20, marginTop: -20 }}
									>
										{etatsupervisor ? (t('common.Stop')) : (t('common.Start'))}
									</Button>
								</form> 
							</Stack> */}
						</Stack>
					)}
				</CardHeader>
				<CardContent sx={{ minHeight: '80%', alignContent: 'center' }}>
					{imageSource ? (
						isScreenSplitted ? (
							<Box
								style={{
									height: '300px',
									display: 'flex',
									position: 'relative',
								}}>
								{/* Second image */}
								<img
									ref={videoFrameRefThermal}
									src={imageSourceThermal}
									alt='Video Frame2'
									style={{
										height: '300px',
										width: '49%', // Takes up half the width of the container
										objectFit: 'cover', // Ensures the image covers the area without stretching
									}}
								/>

								{/* First image */}
								<img
									ref={videoFrameRef}
									src={imageSource}
									alt='Video Frame'
									style={{
										height: '300px',
										marginLeft: '2%',
										width: '49%', // Takes up half the width of the container
										objectFit: 'contain', // Ensures the image covers the area without stretching
									}}
								/>

								<IconButton
									onClick={toggleFullScreenTherma}
									sx={{
										position: 'absolute',
										bottom: 8,
										right: '52%',
										backgroundColor: '#fff',
									}}>
									<Fullscreen />
								</IconButton>

								<IconButton
									onClick={toggleFullScreen}
									sx={{
										position: 'absolute',
										bottom: 8,
										right: 8,
										backgroundColor: '#fff',
									}}>
									<Fullscreen />
								</IconButton>
							</Box>
						) : (
							<Box style={{ height: '300px', position: 'relative' }}>
								<img
									ref={videoFrameRef}
									src={
										(selectValues?.camera_type as string[])?.[0] === 'RGB'
											? imageSource
											: imageSourceThermal
									}
									alt='Video Frame'
									style={{ height: '300px', width: '100%' }}
								/>

								<IconButton
									onClick={toggleFullScreen}
									sx={{
										position: 'absolute',
										bottom: 8,
										right: 8,
										backgroundColor: '#fff',
									}}>
									<Fullscreen />
								</IconButton>
							</Box>
						)
					) : isWebSocketConnected && isVideoPlaying ? (
						<Stack
							direction='row'
							justifyContent='center'
							alignItems='center'
							style={{ height: '300px' }}>
							<CircularProgress />
						</Stack>
					) : (
						<Stack
							alignItems={'center'}
							justifyContent={'center'}
							textAlign='center'
							alignContent={'center'}>
							<WifiOff
								sx={{
									height: 60,
									width: 60,
									marginBottom: '10px',
								}}
							/>
							<Typography>{t('flux.Erreur de connexion')}</Typography>
						</Stack>
					)}
				</CardContent>
				{
					//start stop supervisor
					/* {inLive ? (
				isWebSocketConnected || imageSource && ( */
				}
				{/* <form onSubmit={handleSubmit}>
				<Button
					type='submit'
					variant='outlined'
					style={{ justifySelf: "end", alignSelf: "end", marginLeft: 20, marginTop: -20 }}

				>
					{etatsupervisor ? (t('common.Stop')) : (t('common.Start'))}
				</Button>
			</form> */}
				{/* )) : null} */}
			</Card>
		</div>
	);
};

export default VideoPlayer;
